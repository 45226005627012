@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

html {
    --nav-background: #eaeaea;
    --link-color: #5E704E;
    --primary-text-color: #000000;
    --primary-background: #ffffff;
    --secondary-text-color: #000;
    --secondary-background: #eaeaea;
    --hover-brightness: 1.6;

    scroll-behavior: smooth;
  }

.App {
    overflow-x: hidden;
    color: var(--primary-text-color)
}

.App a {
    color: var(--link-color);
}
.App a:hover {
    color: var(--link-color);
    filter: brightness(var(--hover-brightness));
    text-decoration: none;
}

.nav {
    background-color: var(--nav-background);
}

.section-a {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: var(--secondary-background);
    color: var(--secondary-text-color);
}
.section-a p {
    font-family: 'Roboto Mono', sans-serif;
}

.background-img {
    background-image: url("Imgs/code-583073.jpg");
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 60%;
    width: 90%;
    margin: 0 5%;
}

.img-title {
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative;
    z-index: 1;
    text-align: center;
    font-family: 'Roboto Mono', sans-serif;
    color: #fff;
    font-size: 3.6em;
}

.card-container {
    position: relative;
    z-index: 1;
    height: 40%;
}

.card {
    box-shadow: 1px 0px 8px 0px rgba(0,0,0,.5);
    border: none !important;
    border-radius: 0 !important;
}

.section-b {
    background-color: var(--primary-background);
    color: var(--primary-text);
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'Roboto Mono', sans-serif;
}

.footer {
    font-family: 'Roboto Mono', sans-serif;
    background-color: var(--primary-background);
    color: var(--primary-text);
    height: 100px;
    padding-top: 25px;
    padding-bottom: 50px;
    text-align: center;
}

.hr-accent {
    border-color: grey;
}

.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

@media only screen and (max-width: 700px) {
    .section-a{
        padding: 0;
        width: 100%;
    }

    .background-img {
        width: 100%;
    }

    .story {
        padding: 0 50px !important;
    }
}