@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
    --nav-background: #eaeaea;
    --link-color: #5E704E;
    --primary-text-color: #000000;
    --primary-background: #ffffff;
    --secondary-text-color: #000;
    --secondary-background: #eaeaea;
    --hover-brightness: 1.6;

    scroll-behavior: smooth;
  }

.App {
    overflow-x: hidden;
    color: #000000;
    color: var(--primary-text-color)
}

.App a {
    color: #5E704E;
    color: var(--link-color);
}
.App a:hover {
    color: #5E704E;
    color: var(--link-color);
    -webkit-filter: brightness(1.6);
            filter: brightness(1.6);
    -webkit-filter: brightness(var(--hover-brightness));
            filter: brightness(var(--hover-brightness));
    text-decoration: none;
}

.nav {
    background-color: #eaeaea;
    background-color: var(--nav-background);
}

.section-a {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #eaeaea;
    background-color: var(--secondary-background);
    color: #000;
    color: var(--secondary-text-color);
}
.section-a p {
    font-family: 'Roboto Mono', sans-serif;
}

.background-img {
    background-image: url(/static/media/code-583073.1b4546d5.jpg);
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 60%;
    width: 90%;
    margin: 0 5%;
}

.img-title {
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative;
    z-index: 1;
    text-align: center;
    font-family: 'Roboto Mono', sans-serif;
    color: #fff;
    font-size: 3.6em;
}

.card-container {
    position: relative;
    z-index: 1;
    height: 40%;
}

.card {
    box-shadow: 1px 0px 8px 0px rgba(0,0,0,.5);
    border: none !important;
    border-radius: 0 !important;
}

.section-b {
    background-color: #ffffff;
    background-color: var(--primary-background);
    color: var(--primary-text);
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'Roboto Mono', sans-serif;
}

.footer {
    font-family: 'Roboto Mono', sans-serif;
    background-color: #ffffff;
    background-color: var(--primary-background);
    color: var(--primary-text);
    height: 100px;
    padding-top: 25px;
    padding-bottom: 50px;
    text-align: center;
}

.hr-accent {
    border-color: grey;
}

.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

@media only screen and (max-width: 700px) {
    .section-a{
        padding: 0;
        width: 100%;
    }

    .background-img {
        width: 100%;
    }

    .story {
        padding: 0 50px !important;
    }
}
.header-container {
  height: 100vh;
  width: 100%;
  background: var(--primary-background);
  background-size: cover;
  display: flex;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
}

.header-title {
  font-family: 'Roboto Mono', sans-serif;
  color: var(--text-color);
  font-size: 2em;
  font-weight: 200;
  width: 50%;
}

.header-img-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.header-img {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.header-img img{
  width: auto;
  height: 100%;
  margin-left: -50px;
  background-color: var(--secondary-background);
}

.story-button {
  margin: 2rem 0 0 6rem;
  font-size: 1rem;
  padding: .5rem;
  border-radius: 15px;
}
.story-button button:focus-visible {
  outline: none;
}

@media only screen and (max-width: 1630px) {
  .header-title {
      /* font-size: 3.4em; */
  }
}
  
@media only screen and (max-width: 1300px) {
  .header-title {
      /* padding-left: 30%; */
      /* font-size: 2.4em; */
  }
}

@media only screen and (max-width: 700px) {
  .header-title {
      /* padding-top: 40%;
      padding-bottom: 10%;
      padding-left: 25%;
      font-size: 1.4em; */
  }
}
