.header-container {
  height: 100vh;
  width: 100%;
  background: var(--primary-background);
  background-size: cover;
  display: flex;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
}

.header-title {
  font-family: 'Roboto Mono', sans-serif;
  color: var(--text-color);
  font-size: 2em;
  font-weight: 200;
  width: 50%;
}

.header-img-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.header-img {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.header-img img{
  width: auto;
  height: 100%;
  margin-left: -50px;
  background-color: var(--secondary-background);
}

.story-button {
  margin: 2rem 0 0 6rem;
  font-size: 1rem;
  padding: .5rem;
  border-radius: 15px;
}
.story-button button:focus-visible {
  outline: none;
}

@media only screen and (max-width: 1630px) {
  .header-title {
      /* font-size: 3.4em; */
  }
}
  
@media only screen and (max-width: 1300px) {
  .header-title {
      /* padding-left: 30%; */
      /* font-size: 2.4em; */
  }
}

@media only screen and (max-width: 700px) {
  .header-title {
      /* padding-top: 40%;
      padding-bottom: 10%;
      padding-left: 25%;
      font-size: 1.4em; */
  }
}